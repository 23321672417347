:root {
  --primary: rgb(51, 51, 51);
  --primary-transparent: rgba(51, 51, 51, 0.1);
  --faded: rgb(51, 51, 51, 0.7);
  --footer-background: #000;
  --background: #ffffff;
  --border: #ccc;
  --accent: #2775bb;
  --darkgrey: #727272;
  --grey: #767676;
  --lightgrey: #aaa;
  --green: #2eb771;
  --yellow: #dea152;
  --red: #bb2638;
  --scrollbar: #f5f5f5;
  --filter: invert(0);
}

[data-theme='dark'] {
  --primary: #fff;
  --primary-transparent: rgba(255, 255, 255, 0.3);
  --footer-background: #000;
  --background: #111;
  --border: #666;
  --accent: #2775bb;
  --darkgrey: #3b3b3b;
  --grey: #b2b2b2;
  --lightgrey: #1e1f23;
  --green: #2eb771;
  --yellow: #dea152;
  --red: #bb2638;
  --scrollbar: #1e1f23;
  --filter: invert(1);
}

html,
body {
  margin: 0;
  color: var(--primary);
  background: var(--background);
  font-size: 16px;
}

input,
select {
  font-size: 100%;
}

* {
  transition: background-color 0.5s ease;
}
