@font-face {
  font-family: 'NeoSans';
  src: local('NeoSans'),
    url(./assets/fonts/NeoSans/NeoSansArabic.woff) format('woff');
}

html {
  font-size: 62.5%;
}

body {
  font-family: 'NeoSans';
  font-size: 1.6rem;
}

.container {
  overflow-y: hidden;
}

#root {
  background: var(--background);
}

*:focus {
  outline-color: var(--primary);
}
